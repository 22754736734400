<template>
  <div>
    <!-- 正式 -->
    <van-cell title="就诊人" :value="userName" />
    <van-cell title="开立医生" :value="lists.drName" />
    <van-cell title="开立时间" :value="lists.operTime" />
    <van-cell title="支付状态" v-if="lists.payStatus === 0">
      <template slot="default">
        <span style="color: red">未支付</span>
      </template>
    </van-cell>
    <van-cell title="总金额" :value="feeTotal + '元'" />
    <!-- </van-cell-group>
      <van-cell-group v-for="item in list" :key="item + 'OrdIn'"> -->
    <div class="box-append" @click="handleActionsheet">
      查看明细
      <div class="nav-mobile-button"></div>
    </div>
    <div class="actionsheet" v-show="actionsheetVisible">
      <div class="actionsheet-mask" @click="handleActionsheet"></div>

      <div class="actionsheet-panel">
        <div class="actionsheet-hd">
          <div class="actionsheet-hd-tt">处方明细</div>
          <i class="actionsheet-icon-close" @click="handleActionsheet"></i>
        </div>
        <!-- <div
                class="actionsheet-box-item">
                <div class="actionsheet-box-item-label-wrap">
                  <div class="actionsheet-box-item-label">{{lists.userName}}</div>
                  <div class="actionsheet-box-item-sublabel">{{lists.userName}}</div>
                </div>
                <div 
                  class="actionsheet-box-item-value em"
                  >{{this.feeTotal}}</div>
              </div> -->

        <div class="actionsheet-box-hd">费用信息</div>
        <div class="actionsheet-box-bd">
          <van-cell-group v-if="list.length > 0">
            <van-cell v-for="Ditem in list[0].recipes" :key="Ditem + 'OrdIn'">
              <template slot="title">
                <span style="color: #969799">{{ Ditem.deptName }}</span>
                <!-- <span style="float:right">{{ Ditem.totalCost }}元</span> -->
              </template>
              <template slot="label">
                <div v-for="(item, index) in Ditem.clinicDetails" :key="index">
                  <span>{{ item.itemName }}</span>
                  <span style="float:right">{{ item.cost }}元</span>
                </div>
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
    </div>
    <van-list style="margin-top: 5px;margin-bottom:50px;">
      <!-- <van-cell-group>
          <van-cell title="项目名称">
            <template slot="default">
              <span style="color: #57c4b7">金额(元)</span>
            </template>
          </van-cell>
          <van-cell v-for="Ditem in list" :key="Ditem + 'OrdIn'">
            <template slot="title">
              <span style="color: #969799">{{ Ditem.payName }}</span>
              <span style="float:right">{{ Ditem.totalCost }}元</span>
            </template>
            <template slot="label">
              <div v-for="(item,index) in Ditem.clinicDetails" :key="index">
              <span>{{ item.itemName }}</span>
              <span style="float:right">{{ item.totalCost }}元</span>
              </div>
            </template>
          </van-cell>
        </van-cell-group> -->
      <van-radio-group v-model="radio">
        <van-cell-group style="margin-top:13px;">
          <van-cell
            title="微信支付"
            style="font-size:19px;height:56px;line-height:35px"
            clickable
            @click="radio = '1'"
          >
            <template #icon>
              <span style="margin-right:15px;"
                ><img
                  :src="weixicon"
                  alt=""
                  style="margin-left:5px;width:40px;height:30px;"
              /></span>
            </template>
            <template #right-icon>
              <van-radio checked-color="#57c4b7" name="1" />
            </template>
          </van-cell>
          <van-cell
            title="医保支付"
            style="font-size:19px;height:56px;line-height:35px"
            clickable
            @click="radio = '2'"
          >
            <template #icon>
              <span style="margin-right:10px;"
                ><img :src="ybicon" alt="" style="width:50px;height:15px;"
              /></span>
            </template>
            <template #right-icon>
              <van-radio checked-color="#57c4b7" name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-list>

    <!-- <div style="margin:10px auto;">
        <van-cell  :value="lists.drName" >
           <template slot="title">
              <span style="color:red;">*</span>
              <span>是否使用个人账户支付：</span>
            </template>
         <template slot="default">
            <van-radio-group v-model="radio"  direction="horizontal">
            <van-radio name="1" checked-color="#57c4b7">使用</van-radio>
            <van-radio name="0" checked-color="#57c4b7">不使用</van-radio>
          </van-radio-group>
            </template>
         
          </van-cell>
        </div> -->
    <van-button
      v-if="radio == 2"
      type="primary"
      style=" position: fixed;bottom:3%;left:5%;right:5%; width:90%; height: 50px;"
      color="#57c4b7"
      round
      size="large"
      @click="gotoPay(lists)"
    >
      前往医保支付
    </van-button>
    <van-button
      v-else-if="radio == 1"
      type="primary"
      style=" position: fixed;bottom:3%;left:5%;right:5%; width:90%; height: 50px;"
      color="#57c4b7"
      round
      size="large"
      @click="
        onclickPay(
          lists.regNo,
          lists.deptName,
          lists.drName,
          lists.recipes,
          lists.totalFee,
          lists.content
        )
      "
    >
      前往支付
    </van-button>

    <div
      style="font-size: 12px;color: #d3d3d3;
          margin: 1% 40%;
          width: auto;
          height: 40px;
        "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import appconst from "../../lib/appconst";
import ajax from "../../lib/ajax";
import store from "../../store";
const OperatorId = "100002";
let orderid = "";
export default {
  data() {
    return {
      weixicon: require("../../assets/weixpay.jpg"),
      ybicon: require("../../assets/medical-logo.png"),
      actionsheetVisible: false,
      wxopenid: "",
      authCode: "",
      regNo: "",
      idCard: "",
      userName: "",
      qrCodeText: "",
      cardNo: "",
      radio: "1",
      payArg: {},
      list: [],
      lists: {},
      loading: false,
      finished: false,
      feeTotal: 0,
      wxPayInfo: {
        timestamp: "",
        nonceStr: "",
        package: "",
        signType: "",
        paySign: "",
      },
      wxconfig: {
        debug: false,
        appId: "",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsApiList: ["chooseWXPay", "hideOptionMenu"],
      },
    };
  },
  created() {
    // "oA1lZs3_6fstbC__l7GU-rfJFoVc"//
    this.wxopenid = store.getters["wechat/openid"];
    this.userName = localStorage.getItem("userName");
    this.cardNo = localStorage.getItem("cardNo");
    this.idCard = localStorage.getItem("idCard");
    this.regNo = this.$route.query.regNo;
    // this.getPayAuth();

    // this.phone = localStorage.getItem("phone");
    this.onLoad();
    // this.getPayAuth();
  },
  methods: {
    isWeixin() {
      return /MicroMessenger/gi.test(navigator.userAgent);
    },
    initWxConfig() {
      // 初始化微信JS功能配置
      let me = this;
      return new Promise((resolve, reject) => {
        me.getWxjsSign(appconst.wechatAppId)
          .then((x) => {
            // alert(appconst.wechatAppId);
            var config = {
              appId: appconst.wechatAppId,
              timestamp: x["timestamp"],
              nonceStr: x["noncestr"],
              signature: x["sign"],
              ticket: x["ticket"],
            };
            me.wxconfig = Object.assign(me.wxconfig, config);
            wx.ready((x) => {
              if (x) {
                //
              }
              resolve(me.wxconfig);
            });
            wx.error((err) => {
              if (err) {
                //
              }
              reject(x);
            });
            wx.config(me.wxconfig);
            // alert(wx);
          })
          .catch((x) => {
            reject(x);
          });
      });
    },
    // 获取微信JS签名
    getWxjsSign() {
      let url = encodeURIComponent(location.href);
      var center = `${appconst.thirdApiCenterBaseUrl}Api/WxMpOauth/JsSign`;
      return new Promise((resolve, reject) => {
        ajax
          .get(center, {
            params: {
              appid: appconst.wechatAppId,
              url: url,
            },
          })
          .then((x) => {
            resolve(x.data.result);
            console.log("测试" + x.data.result);
          })
          .catch((x) => {
            reject(x);
            console.log(x);
          });
      });
    },
    async onLoad() {
      let me = this;
      if (me.isWeixin()) {
        me.initWxConfig();
        wx.hideOptionMenu(); // 禁用分享

        // TODO：加载订单信息用于显示
      } else {
        Toast.fail({ message: "请在微信中打开此页面", duration: 0 });
      }
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.list.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length == this.list.length) {
          this.finished = true;
        }
      }, 500);
      // let regno = store.getters["store/regNo"];
      // let patientid = store.getters['store/patientid'];//声明病例号
      // console.log("挂号流水号"+regno);
      // console.log("病例号"+patientid);
      let regNo = this.regNo; //store.getters["store/regNo"];
      // let idcard = store.getters["store/idcard"];
      // let seqNo = store.getters["store/seqNo"];
      // console.log(seqNo);
      // seqNo = "";
      // let patientid = store.getters["store/patientid"]; //声明病例号
      // let idcard = this.idCard; //store.getters["store/idcard"];
      Toast.loading({
        message: "数据加载中...",
        forbidClick: true,
        duration: 0,
      });
      let outpatientId = localStorage.getItem("userId");

      ajax
        .post("/Api/FeeService/GetFeeList", {
          userID: outpatientId,
          payStatus: 0,
          operatorId: "100002",
          payWay: 3,
          machineName: "wechat",
        })
        .then((rep) => {
          console.log(11111111, rep.data.result.clinicFees);
          me.list = rep.data.result.clinicFees;
          this.payArg = rep.data.result.payArgs;

          me.list = me.list.filter((item) => item.regNo == regNo);
          console.log("me.list为", me.list);
          for (let i = 0; i < me.list.length; i++) {
            const el = me.list[i];
            this.feeTotal += el.totalFee;
          }
          this.lists = me.list[0];
          Toast.clear();
          // console.log(me.list);
          console.log(me.lists);
        })
        .catch(() => {
          Toast("请求失败，请稍后重试！");
        });

      // await ajax
      //   .post("/Api/FeeService/GetFeeDetail", {
      //     regNo: regNo,
      //     seqNo: seqNo,
      //     idCard: "533025199707123317",
      //   })
      //   .then((rep) => {
      //     me.list = rep.data.result.data[0];
      //     console.log(me.list);
      //     console.log(me.list[0].operTime);
      //     me.list[0].operTime =
      //       me.list[0].operTime.substring(4, 6) +
      //       "/" +
      //       me.list[0].operTime.substring(6, 8) +
      //       "/" +
      //       me.list[0].operTime.substring(8, 10) +
      //       ":" +
      //       me.list[0].operTime.substring(10, 12) +
      //       ":" +
      //       me.list[0].operTime.substring(12, 14);
      //     // alert(me.list[0].operTime);
      //     me.lists = me.list[0].clinicDetails;
      //     console.log(me.lists);
      //   })
      //   .catch(() => {
      //     Toast("请求失败，请稍后重试！");
      //   });
    },
    gotoPay() {
      // 查询是否已经缴费正在处理状态
      // ajax
      //   .get(`Api/WechatAgentService/AgentExist?regno=${this.regNo}`)
      //   .then((res) => {
      //     console.log(res);
      //     if (res.data.result == false) {
      // 正式模式下
      // http://wx.fgxrmyy.cn/Mpay?regNo=${this.regNo}`}
      ajax
        .get(
          `/Api/WxYBPayServices/GetWechatAuthorizationUrl?retUrl=http://wx.fgxrmyy.cn/medicarePay?regNo=${this.regNo}`
        )
        .then((res) => {
          if (res.data.result) {
            window.location.href = res.data.result;
          } else {
            Toast("获取医保授权连接失败");
          }
          console.log(res);
        })
        .catch(() => {
          Toast("获取医保授权连接失败");
        });
      // 测试、开发模式下
      // this.$router
      //   .replace({
      //     path: `/medicarePay?regNo=${this.regNo}&authCode=364145777370394971&retCode=0`,
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // 结束
      //   } else {
      //     Dialog.alert({
      //       title: "提示",
      //       message: "该订单已缴正在处理中,请5分钟后再试！",
      //     }).then(() => {
      //       this.$router.go(-1);
      //       return;
      //       // on close
      //     });
      //   }
      // });
    },
    // 支付
    async onclickPay(regNo, deptName, drName, recipes, sumfee, content) {
      window.event.stopPropagation();
      this.disabled = true;
      Toast.loading({
        message: "正在处理中，请稍后...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let me = this;
      // 将要进行支付前 拟定订单
      let openId = store.getters["wechat/openid"];
      let idcard = store.getters["store/idcard"];
      let outpatientId = store.getters["store/patientid"];
      // outpatientId = "0001052852";
      let phone = store.getters["store/phone"];
      var userId = store.getters["store/userId"];
      for (let i = 0; i < recipes.length; i++) {
        const element = recipes[i];
        recipes[i].repNoList = element.recipeNO;
        recipes[i].fee = element.totalCost;
      }
      // console.log(itemName);
      ajax
        .post("/Api/PayService/NewOrder", {
          userID: outpatientId,
          userName: this.PatientName,
          idCard: idcard,
          phone: phone,
          hasCard: false,
          cardNo: this.CardNumber,
          title: "门诊缴费",
          content: content,
          // content:depname+'|'+dorname+'|'+regLevel+'|'+0.01,
          description: `微信公众号支付|${this.PatientName}|${sumfee}`,
          // description:"微信公众号支付"+'|'+userName+'|'+0.01,
          // cost:0.01,
          cost: sumfee,
          machineName: "wechat",
          wxOpenID: openId,
        })
        .then(async (res) => {
          orderid = res.data.result;
          // alert(orderid);
          if (orderid > 0) {
            await me.getWxPayInfo();
            await ajax
              .post("/Api/WechatAgentService/AgentFeePaid", {
                // orderId: orderid,
                // regNo: regNo,
                // seqNos: seqNos,
                // termialType: null,
                // payWay: 3,
                // cardPayData: null,
                // hisFromDept: deptName,
                // hisFromDoctor: drName,
                // hisFromCreateTime: operTime,
                // operatorId: OperatorId,
                // machineName: "wechat",
                // patientID: userId,
                // openID: openId,
                // idCard: idcard,
                // userName: this.PatientName,
                // hisFromDept: deptName,
                // hisFromDoctor: drName,
                // hisFromCreateTime: operTime,
                orderId: orderid,
                list: recipes,
                patientID: userId,
                registerNO: regNo, //挂号流水号
                cardTypeID: 4,
                cardNO: idcard,
                payType: {
                  payTypeID: 3,
                  payModeName: "微信",
                  // openBank: "string",
                  // payCardNo: "string",
                  // posTransNo: "string",
                  amount: sumfee,
                },
                operatorId: OperatorId,
                payWay: 3,
                machineName: "wechat",

                // position: "",
                // orderId: orderid,
                // termialType: null,
                // regNo: regNo,
                // payArg: this.payArg[regNo],
                // payWay: "微信",
                // cardPayData: null,
              })
              .then(async (res) => {
                if (res.data.result === 0 || res.data.result === 2) {
                  Toast.clear();
                  wx.chooseWXPay({
                    // appId: me.wxPayInfo.appId,
                    timestamp: me.wxPayInfo.timestamp,
                    nonceStr: me.wxPayInfo.nonceStr,
                    package: me.wxPayInfo.package,
                    signType: me.wxPayInfo.signType,
                    paySign: me.wxPayInfo.paySign,
                    success: async function(res) {
                      let resop = res.errMsg;
                      Toast(resop);
                      Toast(orderid);
                      if (resop === "chooseWXPay:ok") {
                        //更新订单
                        // store.commit("store/setOrderId",orderid);
                        // sessionStorage.setItem("orderid", orderid);
                        // sessionStorage.setItem("regNo", regNo);
                        // sessionStorage.setItem("seqNos", seqNos);
                        // sessionStorage.setItem("deptName", deptName);
                        // sessionStorage.setItem("drName", drName);
                        // sessionStorage.setItem("operTime", operTime);
                        // sessionStorage.setItem("userName", this.PatientName);
                        // sessionStorage.setItem("sumfee", sumfee);
                        // sessionStorage.setItem("itemName", itemName);
                        Toast("缴费成功，请注意查看缴费情况");
                        window.location.href = "/FWait";
                      }
                      //window.location.href = "/WechatPay/Result?returnurl="+encodeURIComponent("@ViewData["returnurl"]")+"&oid=" + '@ViewData["oid"]' + "&state=" + /\w+$/.exec(res.errMsg)[0];
                    },
                    fail: async function(res) {
                      // Toast.clear();
                      if (res) {
                        this.disabled = false;
                        Toast("您已取消缴费");
                      }
                      console.log("fail");
                    },
                    cancel: async function(res) {
                      // Toast.clear();
                      if (res) {
                        this.disabled = false;
                        Toast("您已取消缴费");
                      }
                      console.log("cancel");
                    },
                  });
                } else if (res.data.result === 1) {
                  Toast.clear();
                  Toast("您已缴费成功，请及时查看缴费情况");
                } else {
                  Toast.clear();
                  Toast("缴费失败，系统异常" + res.data.result);
                }
              })
              .catch(() => {
                Toast.clear();
                // Toast("出现未知错误：" + err);
              });
          } else {
            Toast("创建订单失败");
          }
          // console.log(orderid);
        })
        .catch(() => {
          // 更新orderid
        });
    },
    async getWxPayInfo() {
      let me = this;
      //const query = $route;  // 从Url上获取订单Id
      //me.orderId = query.orderId;
      me.orderId = orderid;
      // console.log("123456");
      console.log("orderid是" + me.orderId);
      await ajax
        .post("/Api/PayService/CreatePay", {
          orderId: orderid,
          payWay: "微信公众号",
          operatorId: OperatorId,
        })
        .then((rep) => {
          let res = rep.data.result;
          console.log("res是：" + JSON.stringify(res));
          if (res.result === 1) {
            let dat = JSON.parse(res.value);
            // alert("dat是"+ JSON.stringify(dat));
            // me.wxPayInfo.appId = dat.AppId;
            me.wxPayInfo.timestamp = dat.TimeStamp;
            me.wxPayInfo.nonceStr = dat.NonceStr;
            me.wxPayInfo.package = dat.Package;
            me.wxPayInfo.signType = dat.SignType;
            me.wxPayInfo.paySign = dat.PaySign;
            // console.log("公众号Id：" + me.wxPayInfo.appId);
            console.log("时间戳" + me.wxPayInfo.timestamp);
            console.log("随机字符串" + me.wxPayInfo.nonceStr);
            console.log("订单详情拓展字符串" + me.wxPayInfo.package);
            console.log("签名方式" + me.wxPayInfo.signType);
            console.log("签名" + me.wxPayInfo.paySign);
          } else {
            Dialog.alert({
              message: "签名验证失败",
            }).then(() => {
              //
            });
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
    handleActionsheet() {
      this.actionsheetVisible = !this.actionsheetVisible;
    },
  },
};
</script>

<style scoped>
.box-append {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  text-align: center;
  margin-top: 5px;
  padding: 10px 0 5px;
  background-color: #fff;
}
.nav-mobile-button {
  /* float: right; */
  /* Text-align:center; */
  counter-reset: unset;
  width: 30px;
  height: 1px;
  /* margin-top: 8px; 
   margin-left: 50%; */
  margin: 8px auto;
  border-top: 1px solid #969799;
  border-bottom: 1px solid #969799;
  background-color: #969799;
  padding: 3px 0;
  background-clip: content-box;
}
.box-append:before {
  content: "";
  position: absolute;
  left: -8px;
  bottom: -3px;
  right: -8px;
  border-top: 1px dashed #8e8e8e;
  transform: scaleY(0.5);
}
.actionsheet-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.actionsheet-panel {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 1001;
  border-radius: 16px 16px 0 0;
  max-height: 93vh;
  overflow-y: auto;
}
.actionsheet-hd {
  display: flex;
  justify-content: space-between;
  padding: 27px 20px 17px;
}
.actionsheet-hd-tt {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #303133;
}
.actionsheet-icon-close {
  display: block;
  width: 24px;
  height: 24px;
  background: url(".../../../../assets/info-icon.png") center center no-repeat;
  background-size: 100% 100%;
}
.actionsheet-box {
  border: 1px solid #f8f8f8;
  border-radius: 6px;
  margin: 10px;
}
.actionsheet-box-hd {
  position: relative;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  color: #303133;
  margin: 25px 0 17px;
  padding: 0 20px;
}
.actionsheet-box-hd:before {
  content: "";
  position: absolute;
  left: 4px;
  top: 50%;
  width: 4px;
  height: 17px;
  transform: translateY(-50%);
  background-color: #57c4b7;
}
.actionsheet-box-bd {
  padding: 20px;
  border-top: 1px solid #f8f8f8;
}
.actionsheet-box-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
}
.actionsheet-box-item + .actionsheet-box-item {
  margin-top: 12px;
}
.actionsheet-box-item-label {
  color: #606266;
}
.actionsheet-box-item-sublabel {
  color: #909399;
}
.actionsheet-box-item-value {
  color: #606266;
}
.actionsheet-box-item-value.em {
  color: #3b71eb;
}
</style>
